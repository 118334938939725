<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key"/>
      </keep-alive>
    </transition>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppMain',
  data() {
    return {
      isReload: true,
    }
  },
  computed: {
    ...mapGetters(["sidebarRouters"]),
    key() {
      return this.$route.path
    },
    cachedViews() {
      let views = [];
      this.sidebarRouters.forEach(item => {
        if (views.includes(item.name)) return
        if (item.meta && !item.meta.noCache) {
          views.push(item.name)
        }
      })
      return views;
    }
  },
  async created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-header+.app-main {
  padding-top: 50px;
}

</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 17px;
  }
}
</style>
