<template>
  <div class="product-menu">
    <!-- <div style="color: #fff">{{activeCategory}}</div> -->
    <el-menu
      :default-active="activeCategory"
      mode="horizontal"
      @select="handleSelect"
      background-color="#131f3e"
      text-color="#fff"
      active-text-color="#fff"
    >
      <template v-for="route in topMenu">
        <el-menu-item :key="route.name" :index="route.name">{{route.meta.title}}</el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeCategory: undefined,
      isRoute: false
    };
  },
  computed: {
    ...mapGetters(["addRoutes", "sidebarRouters"]),
    topMenu() {
      return this.addRoutes.filter(item => {
        return !item.hidden && item.children
      })
    }
  },
  watch: {
    // 监视路由的变化
    $route: {
      handler(val) {
        this.activeCategory = val.meta.category;
        this.isRoute = true;
      },
      immediate: true,
    },
    activeCategory: {
      handler(val) {
        this.$store.dispatch("CurrentRoutes", {
          activeCategory: val,
        });
        if (!this.isRoute && !(this.$route.name == this.sidebarRouters[0].name)) {
          this.$router.push({ name: this.sidebarRouters[0].name })
        }
      },
      immediate: true,
    }
  },
  methods: {
    handleSelect(current) {
      this.activeCategory = current;
      this.isRoute = false;
      // console.log(this.sidebarRouters, 'sidebarRouters-----')
      // this.$store.dispatch("CurrentRoutes", {
      //   activeCategory: current,
      // });
    },
    // change(to) {
    //   this.$store.dispatch("CurrentRoutes", {
    //     activeCategory: this.activeCategory,
    //   });
    // },
  },
};
</script>

<style lang='scss' scoped>
.product-menu{
  margin: 0 auto;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
    >.el-menu-item {
      font-size: 16px;
      &.is-active {
        font-weight: 500;
        border-bottom: 3px solid #3b82fe !important;
      }
    }
  }
}
</style>
