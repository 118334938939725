import request from '@/utils/request'

// 获取路由
export const getRouters = () => {
  return new Promise(resolve => {
    let res = {
      code: 200,
      msg: "操作成功",
      data: [
        {
          component: "Layout",
          hidden: false,
          name: "Home",
          path: "",
          permission:[],
          meta: {
            icon: "",
            link: null,
            noCache: false,
            title: "首页",
          },
          children: [
            {
              component: "index",
              hidden: true,
              name: "Index",
              path: "/index",
              meta: {
                icon: "",
                link: null,
                noCache: false,
                title: "首页",
                category: "Home",
                permission:[],
              }
            }
          ]
        },
        {
          component: "Layout",
          hidden: false,
          name: "Analysis",
          path: "/analysis",
          redirect: "analysis/report",
          meta: {
            icon: "",
            link: null,
            noCache: false,
            title: "分析"
          },
          children: [
            {
              component: "analysis/report/index",
              hidden: false,
              name: "Report",
              path: "/analysis/report",
              meta: {
                icon: "",
                link: null,
                noCache: false,
                title: "全部异常",
                category: "Analysis"
              }
            },
            {
              component: "analysis/report/detail",
              hidden: true,
              name: "ReportDetail",
              path: "/analysis/reportDetail",
              meta: {
                icon: "",
                link: null,
                noCache: false,
                title: "异常详情",
                category: "Analysis"
              }
            }
          ]
        },
        {
          component: "Layout",
          hidden: true,
          name: "Monitor",
          path: "/monitor",
          redirect: "monitor/list",
          meta: {
            icon: "",
            link: null,
            noCache: false,
            title: "监控"
          },
          children: [
            // {
            //   component: "monitor/list/index",
            //   hidden: false,
            //   name: "List",
            //   path: "list",
            //   meta: {
            //     icon: "",
            //     link: null,
            //     noCache: false,
            //     title: "告警列表",
            //     category: "Monitor"
            //   }
            // },
            {
              component: "monitor/alert/index",
              hidden: false,
              name: "Alert",
              path: "alert",
              meta: {
                icon: "",
                link: null,
                noCache: false,
                title: "监测告警",
                category: "Monitor"
              }
            }
          ]
        },
        {
          component: "Layout",
          hidden: false,
          name: "Settings",
          path: "/settings",
          redirect: "settings/dict",
          meta: {
            icon: "",
            link: null,
            noCache: false,
            title: "设置"
          },
          children: [
            // {
            //   component: "settings/appInfo/index",
            //   hidden: true,
            //   name: "AppInfo",
            //   path: "appInfo",
            //   meta: {
            //     icon: "",
            //     link: null,
            //     noCache: false,
            //     title: "应用信息",
            //     category: "Settings"
            //   }
            // },
            {
              component: "settings/config/index",
              hidden: false,
              name: "Config",
              path: "/settings/config",
              meta: {
                icon: "",
                link: null,
                noCache: false,
                title: "项目配置",
                category: "Settings"
              }
            },
            {
              component: "settings/dict/index",
              hidden: false,
              name: "Dict",
              path: "/settings/dict",
              meta: {
                icon: "",
                link: null,
                noCache: false,
                title: "字典管理",
                category: "Settings"
              }
            }
          ]
        }
      ]
    }
    resolve(res);
  })
}
