const storageProjectCode = localStorage.getItem('project-code') || ''

const project = {
  state: {
    projectCode: storageProjectCode
  },

  mutations: {
    CHANGE_PROJECTCODE: (state, code) => {
      state.projectCode = code
    }
  },

  actions: {
    // 修改当前项目
    changeProjectCode({ commit }, code) {
      commit('CHANGE_PROJECTCODE', code)
    }
  }
}

export default project
