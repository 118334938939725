const getters = {
  token: state => state.user.token,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  defaultRoutes:state => state.permission.defaultRoutes,
  sidebarRouters:state => state.permission.sidebarRouters,
  addRoutes: state => state.permission.addRoutes,
  activeCategory: state => state.permission.activeCategory,
  projectCode: state => state.project.projectCode
}
export default getters
