var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: { model: _vm.loginForm, rules: _vm.loginRules },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("先知云平台")]),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  "auto-complete": "off",
                  maxlength: "20",
                  placeholder: "请输入用户名",
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.loginForm,
                      "username",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "loginForm.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  "auto-complete": "off",
                  maxlength: "20",
                  placeholder: "请输入密码",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin($event)
                  },
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.loginForm,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "loginForm.password",
                },
              }),
            ],
            1
          ),
          _vm.captchaOnOff
            ? _c(
                "el-form-item",
                { attrs: { prop: "code" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "55%" },
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请输入验证码",
                      maxlength: "4",
                    },
                    on: { input: _vm.inputCode },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleLogin($event)
                      },
                    },
                    model: {
                      value: _vm.loginForm.code,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.loginForm,
                          "code",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "loginForm.code",
                    },
                  }),
                  _c("div", { staticClass: "login-code" }, [
                    _c("img", {
                      staticClass: "login-code-img",
                      attrs: { src: _vm.codeUrl },
                      on: { click: _vm.getCode },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-checkbox",
            {
              staticStyle: { margin: "0px 0px 25px 0px" },
              model: {
                value: _vm.loginForm.rememberMe,
                callback: function ($$v) {
                  _vm.$set(_vm.loginForm, "rememberMe", $$v)
                },
                expression: "loginForm.rememberMe",
              },
            },
            [_vm._v("记住我")]
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v("登 录")])
                    : _c("span", [_vm._v("登 录 中...")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-login-footer" }, [
      _c("span", [_vm._v("Copyright © 2015-2020 ylm All Rights Reserved.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }