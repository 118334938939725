<template>
  <div class="app-wrapper">
    <div class="fixed-header">
      <navbar />
    </div>
    <div class="main">
      <sidebar class="sidebar-container" v-show="activeCategory !== 'Home' && routeName !== 'ReportDetail'"/>
      <div class="main-container" :style="`margin-left: ${ activeCategory !== 'Home' && routeName !== 'ReportDetail' ? variables.sideBarWidth : '0px'};`">
        <app-main />
      </div>
    </div>
  </div>
</template>

<script>
import { AppMain, Navbar, Sidebar } from './components'
import variables from '@/assets/styles/variables.scss'
import { mapGetters } from 'vuex';

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar
  },
  data() {
    return {
      routeName: ''
    }
  },
  computed: {
    ...mapGetters(["activeCategory"]),
    variables() {
      return variables;
    },
  },
  watch: {
    // 监视路由的变化
    $route: {
      handler(val) {
        this.routeName = val.name
      },
      immediate: true,
    },
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    height: 64px;
    transition: width 0.28s;
  }

  .main {
    position: absolute;
    top: 64px;
    width: 100%;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px);
  }

</style>
