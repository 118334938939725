var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-menu" },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "default-active": _vm.activeCategory,
            mode: "horizontal",
            "background-color": "#131f3e",
            "text-color": "#fff",
            "active-text-color": "#fff",
          },
          on: { select: _vm.handleSelect },
        },
        [
          _vm._l(_vm.topMenu, function (route) {
            return [
              _c(
                "el-menu-item",
                { key: route.name, attrs: { index: route.name } },
                [_vm._v(_vm._s(route.meta.title))]
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }