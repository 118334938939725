var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper" }, [
    _c("div", { staticClass: "fixed-header" }, [_c("navbar")], 1),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("sidebar", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.activeCategory !== "Home" &&
                _vm.routeName !== "ReportDetail",
              expression:
                "activeCategory !== 'Home' && routeName !== 'ReportDetail'",
            },
          ],
          staticClass: "sidebar-container",
        }),
        _c(
          "div",
          {
            staticClass: "main-container",
            style:
              "margin-left: " +
              (_vm.activeCategory !== "Home" && _vm.routeName !== "ReportDetail"
                ? _vm.variables.sideBarWidth
                : "0px") +
              ";",
          },
          [_c("app-main")],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }