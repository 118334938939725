var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "theme-dark",
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "select-project",
              attrs: { placeholder: "请选择项目" },
              on: { change: _vm.projectChange },
              model: {
                value: _vm.projectCode,
                callback: function ($$v) {
                  _vm.projectCode = $$v
                },
                expression: "projectCode",
              },
            },
            _vm._l(_vm.dict.type.project_system_code, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                "background-color": _vm.variables.menuBackground,
                "text-color": _vm.variables.menuColor,
                "active-text-color": _vm.variables.menuColorActive,
                "unique-opened": true,
                mode: "vertical",
              },
            },
            [
              _vm._l(_vm.sidebarRouters, function (route) {
                return [
                  !route.hidden
                    ? _c(
                        "app-link",
                        { key: route.name, attrs: { to: route.path } },
                        [
                          route.name
                            ? _c(
                                "el-menu-item",
                                { attrs: { index: route.name } },
                                [_vm._v(_vm._s(route.meta.title))]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }