<template>
  <div>
    <el-scrollbar wrap-class="scrollbar-wrapper" class="theme-dark">
      <el-select
        v-model="projectCode"
        @change="projectChange"
        placeholder="请选择项目"
        class="select-project"
      >
        <el-option
          v-for="item in dict.type.project_system_code"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-menu
        :default-active="activeMenu"
        :background-color="variables.menuBackground"
        :text-color="variables.menuColor"
        :active-text-color="variables.menuColorActive"
        :unique-opened="true"
        mode="vertical"
      >
        <template v-for="route in sidebarRouters">
          <app-link :key="route.name" v-if="!route.hidden" :to="route.path">
            <el-menu-item :index="route.name" v-if="route.name">{{
              route.meta.title
            }}</el-menu-item>
          </app-link>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppLink from "./Link";
import variables from "@/assets/styles/variables.scss";

export default {
  components: { AppLink },
  dicts: ['project_system_code'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["sidebarRouters"]),
    activeMenu() {
      return this.$route.name;
    },
    variables() {
      return variables;
    },
    projectCode: {
      get() {
        return this.$store.state.project.projectCode
      },
      set(val) {
        this.projectChange(val, false)
      }
    }
  },
  methods: {
    ...mapActions([
      'changeProjectCode'
    ]),
    projectChange(val, reload = true) {
      if (!val) return;
      this.changeProjectCode(val);
      this.$cache.local.set("project-code", val);
      if(reload && this.$route.path !== '/analysis/report') {
        this.$router.push('/analysis/report');
      }
    },
  },
};
</script>
